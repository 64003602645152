import Box from "@vertikal/e-prospera.ui.box";
import Button from "@vertikal/e-prospera.ui.button";
import Divider from "@vertikal/e-prospera.ui.divider";
import Text from "@vertikal/e-prospera.ui.text";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Helmet } from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import GlobalSettings from "../../components/GlobalSettings";
import MainMenu from "../../components/MainMenu/MainMenu";
import useRoutes from "../../hooks/useRoutes";
import {
  MaxWidthWrapper,
  ServiceProviderContent,
  ServiceProviderNavigation,
} from "../../styles/serviceproviders.styles";

const Coinable = () => {
  const routes = useRoutes();
  const [t] = useTranslation();
  const serviceProviderName = "Coinable";
  const pageTitle = "ePróspera Marketplace: Coinable";
  const metaDescription = t("coinable.title");
  const metaTitle = "Coinable";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>,
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />
      </Helmet>
      <GlobalSettings />
      <MainMenu>
        <MaxWidthWrapper>
          <Breadcrumbs theme="dark">
            <Link to="/">
              <Trans>common.home</Trans>
            </Link>
            <Link to="../">
              <Trans>common.marketplace</Trans>
            </Link>
            <span>{serviceProviderName}</span>
          </Breadcrumbs>
          <ServiceProviderContent
            id="service-provider_content"
            style={{ gap: "2.5rem" }}
          >
            <Box
              id="service-provider_content_title"
              style={{ gap: "0.5rem" }}
              display="flex"
              flexDirection="column"
            >
              <Text variant="h2" as="h1">
                {serviceProviderName}
              </Text>
              <Text variant="p" color="gray">
                <Trans>common.banking_and_fintech</Trans>
              </Text>
            </Box>
            <StaticImage
              src="../../images/coinable-hero.png"
              alt={`${serviceProviderName} hero`}
            />
            <Box
              id="service-provider_content_description"
              style={{ gap: "1.5rem" }}
              display="flex"
              flexDirection="column"
            >
              <Text variant="h4" as="h2">
                <Trans>coinable.title</Trans>
              </Text>
              <Text variant="p">
                <Trans>coinable.description</Trans>
              </Text>
            </Box>
            <Box height={4}>
              <StaticImage
                style={{
                  maxWidth: "115.675px",
                }}
                src="../../images/coinable-logo.svg"
                alt={`${serviceProviderName} logo`}
              />
            </Box>
            <Divider />
            <ServiceProviderNavigation
              id="service-provider_content_navigation"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              marginTop={-0.5}
            >
              <Link
                className="no-decoration"
                to="../"
                id="service-provider_content_navigation-back"
              >
                <Button as="div" variant="ghost" color="gray" icon="arrow_back">
                  <Trans>common.marketplace</Trans>
                </Button>
              </Link>
              <Button
                href={routes?.coinableWeb}
                target="_blank"
                rel="noreferrer"
                color="accent"
                id="service-provider_content_navigation-service"
                rightIcon="launch"
              >
                <Trans>common.get_started</Trans>
              </Button>
            </ServiceProviderNavigation>
          </ServiceProviderContent>
        </MaxWidthWrapper>
      </MainMenu>
    </>
  );
};

export default Coinable;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
